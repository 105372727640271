/* eslint-disable max-len */
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, space, fontSizes } = theme

const darkModeCSS = darkMode => {
  if (darkMode) return `
    background-color: ${colors['neutral-background-contrast']};
    p, h2, h3, span {
      color: ${colors['blue-text-strong']};
    }
  `
}

export const Feedbacks = styled.section<{ $darkMode: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colors['neutral-background-medium']};
  padding-top: ${space['spacing-xxxl']};
  padding-bottom: ${space['spacing-xxxl']};

  ${props => darkModeCSS(props.$darkMode)}

  @media (${media.desktop}) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    ${props => darkModeCSS(props.$darkMode)}
  }
`

export const Image = styled.div`
  display: block;
  width: 100%;
  margin-bottom: ${space['spacing-xxs']};
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: ${space['spacing-md']};

  @media (${media.desktop}) {
    h2 {
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: ${space['spacing-xxxs']};
  cursor: grab;

  .location {
    margin-bottom: ${space['spacing-xs']};
  }

  p.depoiment {
    padding-right: ${space['spacing-sm']};
  }

  @media (${media.desktop}) {
    p.name {
      font-size: ${fontSizes['font-size-heading-3']};
    }

    .location {
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`
