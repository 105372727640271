import { memo, useCallback, FC } from 'react'

import { Text } from '@cash-me/react-components'
import NextImage from "next/image"

import { Carousel } from 'components/carousel'
import { Grid, Col } from 'components/grid'
import { pushDataLayer } from 'utils/push-data-layer'

import { feedbackList } from './data'
import { FeedbacksProps } from './feedbacks.model'
import * as S from './styles'

const Feedbacks:FC<FeedbacksProps> = memo(({ darkMode = false }) => {
  const handleDataLayer = useCallback((index: number) => {
    const clientName = feedbackList[index].client

    pushDataLayer({
      category: 'Slider',
      action: 'O que nossos clientes dizem',
      label: `Slider - ${clientName}`
    })
  }, [])

  return (
    <S.Feedbacks $darkMode={darkMode}>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col start={{ desktop: 2 }} col={{ tablet: 5, desktop: 5 }}>
          <S.Image>
            <NextImage
              src="/images/home/feedback.png"
              alt="Depoimento de clientes que fizeram o empréstimo para empresas da CashMe"
              width="600"
              height="417"
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto"
              }} />
          </S.Image>
        </Col>
        <Col start={{ tablet: 7, desktop: 8 }} col={{ tablet: 5, desktop: 4 }}>
          <S.TextWrapper>
            <Text as="h2" uppercase size="body2" weight="medium">
              O que nossos clientes dizem
            </Text>
            <Carousel onChangeIndex={handleDataLayer} navigation={true}>
              {feedbackList.map(({ id, client, location, depoiment }) => (
                <S.CarouselItem key={id}>
                  <Text as="p" className="name" size="heading3" weight="medium">
                    {client}
                  </Text>
                  <Text as="span" className="location" size="body2">
                    {location}
                  </Text>
                  <Text className="depoiment" color="var(--colors-neutral-text-weak)">
                    {depoiment}
                  </Text>
                </S.CarouselItem>
              ))}
            </Carousel>
          </S.TextWrapper>
        </Col>
      </Grid>
    </S.Feedbacks>
  );
})

Feedbacks.displayName = 'Feedbacks'

export default Feedbacks
